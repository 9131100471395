<template src="./EditMasterManagementPage.html"></template>
<style scoped lang="scss" src="./EditMasterManagementPage.scss"></style>

<script>
import {ServiceFactory} from "@/services";

const MasterDataForm = () => import('@/components/form/master-data/MasterDataForm')
const MasterDataService = ServiceFactory.get('masterData')

export default {
  name: 'EditMasterManagementPage',
  components: { MasterDataForm },
  data: function () {
    return {
      isLoading: false,
      masterData: {},
    }
  },
  created() {
   this.getMasterDataDetail(this.$route.params.id || 0);
  },
  methods: {
    async handleSubmit(formData) {
      this.isLoading = true
      const masterUpdated = await MasterDataService.update(this.$route.params.id,formData).catch(() => {});
      this.isLoading = false

      if (!masterUpdated || !masterUpdated.id) return

      this.$notify.success({
        message: '編集は完了しました。',
        showClose: false
      });
      return this.$router.push({name: 'routes.masterManagement'})
    },

    async getMasterDataDetail(id) {
      if (!id) return

      this.isLoading = true
      this.masterData = await MasterDataService.getById(id).catch(() => ({}))
      this.isLoading = false
    }
  }
}
</script>
